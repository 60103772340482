import React, { useState, useEffect } from "react";
import CustomSelect from "./CustomSelect";
import copyIcon from "../Assets/copyIcon.svg";
import downArrow from "../Assets/downArrow.svg";
import { JsonRpcProvider } from "ethers/providers";
import { Contract } from "ethers/contract";
import { formatUnits } from "ethers/utils";
import axios from "axios";
import config from "../config";
import { fetchAddress } from "../utils/fetchAddress";
import fetchTokenPrice from "../utils/fetchTokenPrice";
import { getToken } from "../utils/getToken";
const Portfolio = ({ defaultProtfolioToken }) => {
  //const walletAddress = "0x5D2D18E5d2D29466Ba0bc635Eb7565c7aC2181a6";

  const abi = ["function balanceOf(address) view returns (uint256)"];
  const [isLoading, setisLoading] = useState(false);
  const [isTradingStarted, setisTradingStarted] = useState(true);
  const [balances, setBalances] = useState({ usdt: 0, token: "", name: "" });
  const [user, setUser] = useState(null);
  const [walletAddress, setwalletAddress] = useState("");

  const [tokenName, setTokenName] = useState("ETHUSDT");
  const [shouldWait, setshouldWait] = useState(false);

  const [defaultOption, setDefaultOption] = useState(defaultProtfolioToken);
  const tokenABI = ["function balanceOf(address owner) view returns (uint256)"];

  // function getToken(tokenName) {
  //   if (tokenName == undefined) return;
  //   if (tokenName.toString().includes("ETHUSDT")) {
  //     return {
  //       address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", // WETH address on Polygon
  //       symbol: "ETH",
  //       decimals: 18,
  //       priceFixedValue: 2,
  //       balanceFixedValue: 6,
  //       dcaPercentage: 4.0,
  //       profitTakePercentage: 50.0,
  //     };
  //   }
  //   if (tokenName.toString().includes("SOLUSDT")) {
  //     return {
  //       address: "0xd93f7E271cB87c23AaA73edC008A79646d1F9912", // WETH address on Polygon
  //       symbol: "SOL",
  //       decimals: 9,
  //       priceFixedValue: 2,
  //       balanceFixedValue: 3,
  //       dcaPercentage: 4.0,
  //       profitTakePercentage: 77.0,
  //     };
  //   }
  //   if (tokenName.toString().includes("SHIBUSDT")) {
  //     return {
  //       address: "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec", // WETH address on Polygon
  //       symbol: "SHIB",
  //       decimals: 18,
  //       priceFixedValue: 8,
  //       balanceFixedValue: 1,
  //       dcaPercentage: 4.0,
  //       profitTakePercentage: 28.0,
  //     };
  //   }
  //   if (tokenName.toString().includes("MATICUSDT")) {
  //     return {
  //       address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", // WETH address on Polygon
  //       symbol: "MATIC",
  //       decimals: 18,
  //       priceFixedValue: 4,
  //       balanceFixedValue: 2,
  //       dcaPercentage: 4.0,
  //       profitTakePercentage: 50.0,
  //     };
  //   }

  //   throw new Error(`TOKEN NOT FOUND!`);
  // }
  useEffect(() => {
    setDefaultOption(defaultProtfolioToken);
  }, [defaultProtfolioToken]);
  useEffect(() => {
    setTokenName(defaultOption.pair);
    let tokenName = defaultOption.pair;
    getData(tokenName);
  }, [defaultOption]);
  useEffect(() => {
    setTokenName(defaultOption.pair);
    let tokenName = defaultOption.pair;
    getData(tokenName);
  }, []);
  async function getData(tokenName) {
    if (isLoading) return;
    if (tokenName == undefined) return;
    setisLoading(true);
    //await verifyUser();
    let res = await fetchAddress(tokenName);
    setwalletAddress(res);
    await fetchBalances(tokenName, res);
    await checkTradingStarted(tokenName);
    setisLoading(false);
  }
  async function checkTradingStarted(tokenName) {
    //console.log(tokenName);////
    if (tokenName === "ALL") return;
    const requestOptions = {
      credentials: "include",
      method: "POST", // Using POST method to send data
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
      body: JSON.stringify({ tokenName }), // Convert the JavaScript object to a JSON string
    };

    try {
      setisTradingStarted(true);
      const response = await fetch(`${config.baseURI}isTradingStarted`, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json(); // Assuming the server responds with JSON
      setisTradingStarted(data.result);
      return data.result;
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const fetchBalances = async (tokenName, walletAddress) => {
    setisLoading(true);

    console.log("TokenName: " + tokenName);
    const requestOptions = {
      withCredentials: true,
      params: {
        tokenName, // Add tokenName as a query parameter
      },
    };
    try {
      const response = await axios.get(`${config.baseURI}getProfileBalance`, requestOptions);
      const data = response.data;
      console.log(data);
      let balanceInEther = data.tokenBalance;
      let totalUsdtInTrades = data.totalUsdtInTrades;
      let tempTokenName = tokenName.split("USDT")[0];
      let tokenWorth = data.tokenWorth;

      // const response1 = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${tokenName}`);
      // const priceInUSDT = parseFloat(response1.data.price);
      // const priceInUSDT = await fetchTokenPrice(tokenName);

      // let tokenWorth = parseFloat(balanceInEther) * priceInUSDT;

      // tokenWorth = tokenWorth.toFixed(2);
      let tokenDetails = getToken(tokenName);
      let tempbalanceInEther = balanceInEther.toFixed(tokenDetails.balanceFixedValue);

      let pnl = parseFloat(tokenWorth) - parseFloat(totalUsdtInTrades);
      pnl = pnl.toFixed(2);
      // console.log(pnl);
      // console.log(isNaN(pnl));
      tokenWorth = tokenWorth ? tokenWorth : "0.0";
      let inProfit = false;
      let pnltext = "";
      let balanceText = tempbalanceInEther + ` (~ ${tokenWorth}$)`;
      if (pnl != 0 && pnl != undefined && isNaN(pnl) == false) {
        //pnltext += pnl > 0 ? ` ↓ ${pnl}$` : ` ↑ ${pnl}$`;
        if (pnl > 0) {
          pnltext = ` ↑ ${pnl}$`;
          inProfit = true;
        } else {
          inProfit = false;
          pnltext = ` ↓ ${pnl}$`;
        }
      }

      setBalances({
        usdt: parseFloat(data.usdtBalance).toFixed(2), // Assuming USDT on Polygon has 6 decimals
        token: balanceText,
        name: tempTokenName,
        inProfit,
        pnl,
        pnltext,
      });
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching balances:", error);
      setisLoading(false);
    }
    return;
    const provider = new JsonRpcProvider("https://polygon-rpc.com/");
    const usdtAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
    let tokenDetails = getToken(tokenName);
    const tokenAddresses = {
      ETHUSDT: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      SHIBUSDT: "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
      MATICUSDT: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      SOLUSDT: "0xd93f7E271cB87c23AaA73edC008A79646d1F9912",
    };

    try {
      const usdtContract = new Contract(usdtAddress, abi, provider);
      const usdtBalance = await usdtContract.balanceOf(walletAddress);
      const tokenContract = new Contract(tokenDetails.address, abi, provider);
      const tokenBalance = await tokenContract.balanceOf(walletAddress);
      //console.log("tokenBalance: " + tokenBalance + typeof tokenBalance);

      let balanceInEther = parseFloat(formatUnits(tokenBalance.toString(), tokenDetails.decimals)).toFixed(tokenDetails.balanceFixedValue);
      //console.log("balanceInEther: " + balanceInEther);
      let tempTokenName = tokenName.split("USDT")[0];

      const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${tokenDetails.symbol}USDT`);
      const priceInUSDT = parseFloat(response.data.price);

      let tokenWorth = parseFloat(balanceInEther) * priceInUSDT;
      tokenWorth = tokenWorth.toFixed(2);

      setBalances({
        usdt: parseFloat(formatUnits(usdtBalance, 6)).toFixed(2), // Assuming USDT on Polygon has 6 decimals
        token: balanceInEther + ` (~ ${tokenWorth}$)`,
        name: tempTokenName,
      });

      await checkTradingStarted(tokenName);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching balances:", error);
      setisLoading(false);
    }
  };
  async function startTrading(tokenName) {
    //console.log(tokenName);
    if (tokenName === "ALL") return;
    if (shouldWait) return;

    setshouldWait(true);
    const requestOptions = {
      credentials: "include",
      method: "POST", // Using POST method to send data
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
      body: JSON.stringify({ tokenName }), // Convert the JavaScript object to a JSON string
    };

    try {
      const response = await fetch(`${config.baseURI}startTrading`, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json(); // Assuming the server responds with JSON
      setisTradingStarted(data.result);
      if (data.result) {
        alert("Trading started");
        // return data.result;
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.log(error);
    }
    setshouldWait(false);
  }
  const verifyUser = async () => {
    try {
      //console.log(config.baseURI + "protected");
      const response = await axios.get(config.baseURI + "protected", {
        withCredentials: true, // to ensure cookies are sent
      });

      const userData = response.data;
      // console.log(userData);
      if (userData.isAuthenticated) {
        setUser(userData.user);
      }
      //  setUser({ id: "100038641275642811376", displayName: "Sharez", email: "dreamfigher11@gmail.com" });
    } catch (error) {
      console.error("Failed to verify user", error);
    }
  };

  const handleSelect = (option) => {
    setDefaultOption(option);
  };

  const [showToast, setShowToast] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => console.log("Something went wrong", err));
  };

  return (
    <section id="portfolio">
      <div className="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="heading text-center text-white">Portfolio</h2>
              <p className="description">
                Welcome to your cryptocurrency portfolio dashboard. Here, you can view and manage your trading accounts with ease. Track your wallet
                address, monitor your balances.
              </p>

              <CustomSelect
                options={config.accountOptions}
                onSelect={handleSelect}
                defaultOption={defaultOption}
                defaultProtfolioToken={defaultProtfolioToken}
              />

              {/* <div className="down-arrow mt-80 mb-80">
                <img src={downArrow} className="img-fluid icon" alt="downArrow" />
              </div> */}
              {isLoading ? (
                <div className="text-center" style={{ alignContent: "center", minHeight: "350px" }}>
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ marginTop: "30px" }} className="address-group">
                    <label style={{ color: "white" }} htmlFor="wallet-address" className="text-center mb-4">
                      Wallet Address:
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="wallet-address"
                        className="form-control"
                        placeholder={walletAddress}
                        value={walletAddress}
                        aria-label="address"
                        disabled
                      />
                      <button className="input-group-text btn" onClick={copyToClipboard}>
                        <img src={copyIcon} className="img-fluid icon" alt="copyIcon" />
                      </button>
                    </div>
                  </div>

                  {showToast && (
                    <div className="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
                      <div id="liveToast" className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body text-center">Address Coppied!</div>
                      </div>
                    </div>
                  )}

                  {!isTradingStarted && (
                    <div className="trading-started">
                      <div className="trading-started-inner text-center">
                        <h4>Look's like your trading hasn't started yet</h4>
                        <button
                          className="btn btn-solid"
                          onClick={() => {
                            startTrading(defaultOption.pair);
                          }}
                        >
                          Start Trading {defaultOption.value}
                        </button>
                      </div>
                      <p className="note">*Minimum 200$ required to start trading.</p>
                    </div>
                  )}

                  <div className="portfolio-balance">
                    <label className="text-center mb-4">Balances:</label>
                    <div className="d-flex">
                      <>
                        <div className="box d-flex flex-column">
                          <span className="balance">{balances.usdt}</span>
                          <span className="coin">USDT</span>
                        </div>
                        <div className="box d-flex flex-column">
                          <span className="balance">
                            {balances.token}{" "}
                            {balances.inProfit ? (
                              <a style={{ color: "#0adf0a", fontSize: "small" }}>{balances.pnltext}</a>
                            ) : (
                              <a style={{ color: "red", fontSize: "small" }}>{balances.pnltext}</a>
                            )}
                          </span>
                          <span className="coin">{balances.name}</span>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
