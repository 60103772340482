import React from 'react'
import Login from '../Components/Login'

const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default LoginPage
