import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "./config";
import LoginPage from "./Pages/LoginPage";
import Withdrawal from "./Pages/Withdrawal";
import WithdrawalNotification from "./Pages/WithdrawalNotification";
import GoogleAuthPage from "./Pages/GoogleAuth";
import AddAuthenticator from "./Pages/AddAuthenticator";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Assets/App.css";

function App() {
  const [user, setUser] = useState(null);
  const verifyUser = async () => {
    try {
      // console.log(config.baseURI + "protected");
      const response = await axios.get(config.baseURI + "protected", {
        withCredentials: true, // to ensure cookies are sent
      });

      const userData = response.data;
      console.log(userData);
      if (userData.isAuthenticated) {
        setUser(userData.user);
      }
      //  setUser({ id: "100038641275642811376", displayName: "Sharez", email: "dreamfigher11@gmail.com" });
    } catch (error) {
      console.error("Failed to verify user", error);
    }
  };
  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <>
      <Router>
        <Navbar user={user} />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/dashboard" element={user ? <Dashboard user={user} /> : <LoginPage />}></Route>
          <Route exact path="/withdrawal" element={user ? <Withdrawal /> : <LoginPage />}></Route>
          <Route exact path="/withdrawal-notification" element={user ? <WithdrawalNotification /> : <LoginPage />}></Route>
          <Route exact path="/google-auth" element={user ? <GoogleAuthPage /> : <LoginPage />}></Route>
          <Route exact path="/add-authenticators" element={user ? <AddAuthenticator /> : <LoginPage />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
