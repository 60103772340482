import React, { useState } from "react";
import CustomSelect from "./CustomSelect";
import { Modal, Button } from "react-bootstrap";
import config from "../config";
import { fetchAddress } from "../utils/fetchAddress";

const DepositModal = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [walletAddress, setwalletAddress] = useState();
  const [isLoading, setisLoading] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      })
      .catch((err) => console.log("Something went wrong", err));
  };

  // const [defaultOption, setDefaultOption] = useState(config.accountOptions[1]);

  const [defaultOption, setDefaultOption] = useState(null);

  const handleSelect = (option) => {
    setDefaultOption(option);
  };
  React.useEffect(() => {
    if (!defaultOption) return;
    async function fetchingAddress() {
      setisLoading(true);
      // console.log("defaultOption: " + defaultOption.pair);
      let res = await fetchAddress(defaultOption.pair);
      // console.log(res);
      setwalletAddress(res);
      setisLoading(false);
    }
    fetchingAddress();
  }, [defaultOption]);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="authentication-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h3 className="text-center color-blue">SELECT TRADING ACCOUNT</h3>
        <p className="sub-heading">ONLY TRANSFER USDT ON Polygon network</p>

        <CustomSelect options={config.accountOptions} onSelect={handleSelect} />
        {defaultOption && isLoading ? (
          <div className="text-center" style={{ alignContent: "center", minHeight: "250px" }}>
            <div className="spinner-border text-white" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {defaultOption && (
              <div className="modal-body-end">
                {defaultOption.volatility && (
                  <p className="sub-heading" style={{ color: "red" }}>
                    This is a high volatility pair. We don't recommend adding more than 10% of your portfolio.
                  </p>
                )}
                <h4>
                  NETWORK: <strong style={{ color: "#0bf90b" }}>POLYGON</strong>
                </h4>
                <label htmlFor="wallet-address">
                  Use this address to deposit <strong style={{ color: "yellow", fontWeight: "900" }}>USDT </strong>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    id="wallet-address"
                    className="form-control"
                    placeholder={walletAddress}
                    value={walletAddress}
                    aria-label="address"
                    disabled
                  />
                  {/* <button className="input-group-text btn" onClick={copyToClipboard}>
                    <img src={copyIcon} className="img-fluid icon" alt="copyIcon" />
                  </button> */}
                </div>
                <button className="btn btn-solid" onClick={copyToClipboard}>
                  Copy Address
                </button>
              </div>
            )}
          </>
        )}

        {showToast && (
          <div className="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
            <div id="liveToast" className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-body text-center">Address Coppied!</div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-inner">
          Make sure to only send <strong>USDT</strong> through <strong>POLYGON</strong> network. Sending incompatible cryptocurrencies or sending
          through a different network may result in irreversible loss.
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DepositModal;
