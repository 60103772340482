import React, { useState, useEffect } from "react";
import waveIcon from "../Assets/waveIcon.svg";
import downArrow from "../Assets/downArrow.svg";
import { Contract } from "ethers";
import { JsonRpcProvider } from "ethers";
import { formatUnits } from "ethers";
import axios from "axios";
import config from "../config";
import DepositModal from "./DepositModal";
import { Button } from "react-bootstrap";
import { getToken } from "../utils/getToken";
const DashboardHero = ({ user, setdefaultProtfolioToken }) => {
  const tokenABI = ["function balanceOf(address owner) view returns (uint256)"];

  const [totalWorth, setTotalWorth] = useState(0);
  const [totalUSDT, setTotalUSDT] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBalancesAndPrices();
  }, []);

  function findOption(pairName) {
    for (let index = 0; index < config.accountOptions.length; index++) {
      const element = config.accountOptions[index];

      if (pairName === element.pair) {
        return config.accountOptions[index];
      }
    }
  }

  const fetchBalancesAndPrices = async () => {
    try {
      setLoading(true); // Set loading to true when fetching starts
      const requestOptions = {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(`${config.baseURI}getTotalBalance`, requestOptions);
      //console.log(res);
      setTotalUSDT(res.data.totalUsdtInvested);
      // setBalances(walletBalances);
      setTotalWorth(res.data.currentMarketValue);
      return;
      const provider = new JsonRpcProvider("https://polygon-rpc.com/");
      const walletBalances = [];
      let total = 0;
      let walletList = await fetchAllAddresses();
      let temptotalUSDT = 0;
      let isDefaultSet = false;
      for (let { walletAddress, tokenName } of walletList) {
        const token = getToken(tokenName);
        const contract = new Contract(token.address, tokenABI, provider);
        const balance = await contract.balanceOf(walletAddress);
        const adjustedBalance = formatUnits(balance, token.decimals);

        // console.log(tokenName + " Balance:" + adjustedBalance);
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${token.symbol}USDT`);
        const priceInUSDT = parseFloat(response.data.price);

        let tokenWorth = parseFloat(adjustedBalance) * priceInUSDT;
        tokenWorth = tokenWorth.toFixed(2);
        if (!isDefaultSet && parseFloat(tokenWorth) > 0) {
          //setdefaultProtfolioToken(findOption(tokenName));
          // console.log("Default token");
          // console.log(findOption(tokenName));
          isDefaultSet = true;
        }
        //console.log("tokenWorth: " + tokenWorth + "$");
        total += parseFloat(tokenWorth);

        const usdtAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

        const usdtcontract = new Contract(usdtAddress, tokenABI, provider);
        const usdtbalance = await usdtcontract.balanceOf(walletAddress);
        const adjustedUsdtBalance = parseFloat(formatUnits(usdtbalance, 6)).toFixed(2);
        total += parseFloat(adjustedUsdtBalance);
        temptotalUSDT += parseFloat(adjustedUsdtBalance);

        walletBalances.push({ token: token.symbol, balance: adjustedBalance, priceInUSDT, tokenWorth });
      }

      setTotalUSDT(temptotalUSDT.toFixed(2));
      // setBalances(walletBalances);
      setTotalWorth(res.data.currentMarketValue);
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when fetching is complete
    }
  };

  async function fetchAllAddresses() {
    //console.log(tokenName);
    // console.log("fetching addresses");
    const requestOptions = {
      credentials: "include",
      method: "POST", // Using POST method to send data
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
    };

    try {
      const response = await fetch(config.baseURI + `fetchAllWallets`, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json(); // Assuming the server responds with JSON
      console.log(data);
      //  console.log("Response data:", data);
      //setDepositAddress(data.walletAddress);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [modalDepositShow, setModalDepositShow] = useState(false);

  return (
    <section id="dashboard-hero">
      <div className="dashboard-hero">
        <div className="container position-relative">
          <span className="star">+</span>
          <span className="star">+</span>
          <span className="star">+</span>
          <div className="row">
            <div className="col-12">
              <h1 className="title text-center poppins-medium">
                Hello&nbsp;<span className="color-blue">{user?.displayName}</span>&nbsp;
                <img src={waveIcon} className="img-fluid icon" alt="waveIcon" />
              </h1>

              {loading ? (
                <div className="text-center">
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="dashboard-buttons d-flex justify-content-center">
                  <button className="btn btn-border">
                    <span className="btn-title">Market Value:</span>
                    <span className="btn-content">{totalWorth} $</span>
                  </button>
                  <button className="btn btn-border">
                    <span className="btn-title">Total Invested:</span>
                    <span className="btn-content">{totalUSDT} $</span>
                  </button>
                </div>
              )}

              <div className="down-arrow mt-80 mb-80">
                <img src={downArrow} className="img-fluid icon" alt="downArrow" />
              </div>

              <div className="dashboard-buttons d-flex justify-content-center">
                {/* <button className="btn btn-solid justify-content-center" onClick={openModal}>
                  DEPOSIT
                </button> */}
                <Button
                  variant="primary"
                  className="modal-button despoit-button btn-solid justify-content-center"
                  onClick={() => setModalDepositShow(true)}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="tag">DEPOSIT</span>
                  </div>
                </Button>

                <DepositModal show={modalDepositShow} onHide={() => setModalDepositShow(false)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardHero;
