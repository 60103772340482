export const getToken = (tokenName) => {
console.log(tokenName);
  if (tokenName == undefined) return;
  if (tokenName.toString().includes("BTCUSDT")) {
    return {
      address: "", // WETH address on Polygon
      symbol: "BTC",
      decimals: 18,
      priceFixedValue: 2,
      balanceFixedValue: 5,
      dcaPercentage: 3.0,
      profitTakePercentage: 50.0,
    };
  }
  if (tokenName.toString().includes("ETHUSDT")) {
    return {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", // WETH address on Polygon
      symbol: "ETH",
      decimals: 18,
      priceFixedValue: 2,
      balanceFixedValue: 6,
      dcaPercentage: 4.0,
      profitTakePercentage: 50.0,
    };
  }
  if (tokenName.toString().includes("SOLUSDT")) {
    return {
      address: "0xd93f7E271cB87c23AaA73edC008A79646d1F9912", // WETH address on Polygon
      symbol: "SOL",
      decimals: 9,
      priceFixedValue: 2,
      balanceFixedValue: 3,
      dcaPercentage: 4.0,
      profitTakePercentage: 77.0,
    };
  }
  if (tokenName.toString().includes("SHIBUSDT")) {
    return {
      address: "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec", // WETH address on Polygon
      symbol: "SHIB",
      decimals: 18,
      priceFixedValue: 8,
      balanceFixedValue: 1,
      dcaPercentage: 4.0,
      profitTakePercentage: 28.0,
    };
  }
  if (tokenName.toString().includes("POLUSDT")) {
    return {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", // WETH address on Polygon
      symbol: "POL",
      decimals: 18,
      priceFixedValue: 4,
      balanceFixedValue: 1,
      dcaPercentage: 4.0,
      profitTakePercentage: 50.0,
    };
  }
  if (tokenName.toString().includes("TIAUSDT")) {
    return {
      symbol: "TIA",
      tradingPair: "TIAUSDT",
      decimals: 18,
      priceFixedValue: 3,
      balanceFixedValue: 2,
      dcaPercentage: 6.0,
      profitTakePercentage: 50.0,
      takeProfitAfterUpperCount: 1,
      slippage: 1,
      volatility: true,
    };
  }
  if (tokenName.toString().includes("INJUSDT")) {
    return {
      symbol: "INJ",
      tradingPair: "INJUSDT",
      decimals: 18,
      priceFixedValue: 2,
      balanceFixedValue: 2,
      dcaPercentage: 6.0,
      profitTakePercentage: 50.0,
      takeProfitAfterUpperCount: 1,
      slippage: 1,
      volatility: true,
    };
  }
  if (tokenName.toString().includes("SUIUSDT")) {
    return {
      symbol: "SUI",
      tradingPair: "SUIUSDT",
      decimals: 18,
      priceFixedValue: 4,
      balanceFixedValue: 1,
      dcaPercentage: 6.0,
      profitTakePercentage: 50.0,
      takeProfitAfterUpperCount: 1,
      slippage: 1,
      volatility: true,
    };
  }

  throw new Error(`TOKEN NOT FOUND!`);
};
