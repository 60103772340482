import React from 'react'
import GoogleAuth from '../Components/GoogleAuthenticator'

const GoogleAuthPage = () => {
  return (
    <>
      <GoogleAuth />
    </>
  )
}

export default GoogleAuthPage