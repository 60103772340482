import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import AuthenticationModal from '../Components/AuthenticationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import EmailModal from '../Components/EmailModal';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

const VerificationData = [
    { id: 1, smallData: "Trading Account", answerText: "Account" },
    { id: 2, smallData: "Network", answerText: "Network" },
    { id: 3, smallData: "Withdrawal Amount", answerText: "20 USD" },
    { id: 4, smallData: "Enter Address", answerText: "0x5D2D18E5d2D29466Ba0bc635Eb7565c7aC2181a6" },
]

const WithdrawalNotification = () => {
    const [modalAuthShow, setModalAuthShow] = useState(false);
    const [modalEmailShow, setModalEmailShow] = useState(false);
    const [authActive, setAuthActive] = useState(false);
    const [emailActive, setEmailActive] = useState(false);
    const [count, setCount] = useState(0);
    const [countFull, setCountFull] = useState(false);
    const [processAuth, setProcessAuth] = useState(true);
    const navigate = useNavigate();

    let intervalId;

    if (count === 2) {
        intervalId = setInterval(() => {
            setCountFull(true);
        }, 2000);
    }
    else if (countFull) {
        clearInterval(intervalId);
    }

    const backPage = () => {
        navigate("/withdrawal");
    }

    //// if process auth rejected set "setProcessAuth" to false

    return (
        <section id="withdrawal-notification">
            <div className="withdrawal-notification">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="heading text-center text-white">SECURITY <span className='color-blue'>VERIFICATION</span> REQUIREMENTS</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare mi a feugiat dignissim. Nulla facilisi</p>
                            <div className='verification-content d-flex align-items-center flex-wrap'>
                                {
                                    VerificationData.map((data, index) => {
                                        return (
                                            <div className="verification-data d-flex align-items-center" key={index}>
                                                <span className='small-text'>{data.smallData}</span>
                                                <span className='answer-text'>{data.answerText}</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {
                                countFull ?
                                    <>
                                        {
                                            processAuth ?
                                                <div className='withdraw-process'>
                                                    <div className='circle-tick'>
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </div>
                                                    <span>withdrawal processing ......</span>
                                                    <div>
                                                         <Button variant="primary btn-solid mx-auto">Continue</Button>
                                                    </div>
                                                </div>
                                                :
                                                <div className='withdraw-process red'>
                                                    <div className='circle-tick'>
                                                        <FontAwesomeIcon icon={faCircleXmark} />
                                                    </div>
                                                    <span>withdrawaL FAILED</span>
                                                    <div>
                                                         <Button variant="primary btn-solid mx-auto" onClick={backPage}>Back</Button>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <div className='number-tag'>
                                            <span>{count}/2</span>
                                        </div>
                                        <div className='modals'>
                                            <Button variant="primary" className={`modal-button ${authActive ? "active" : ""}`} onClick={() => setModalAuthShow(true)}>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='tag'>Authenticator App</span>
                                                    {
                                                        authActive ?
                                                            <span className='arrow text-green'><FontAwesomeIcon icon={faCheck} /></span>
                                                            :
                                                            <span className='arrow'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                    }
                                                </div>
                                            </Button>

                                            <AuthenticationModal
                                                show={modalAuthShow}
                                                onHide={() => setModalAuthShow(false)}
                                                setAuthActive={setAuthActive}
                                                setCount={setCount}
                                                count={count}
                                            />

                                            <Button variant="primary" className={`modal-button ${emailActive ? "active" : ""}`} onClick={() => setModalEmailShow(true)}>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='tag'>Email</span>
                                                    {
                                                        emailActive ?
                                                            <span className='arrow text-green'><FontAwesomeIcon icon={faCheck} /></span>
                                                            :
                                                            <span className='arrow'><FontAwesomeIcon icon={faArrowRight} /></span>
                                                    }
                                                </div>
                                            </Button>

                                            <EmailModal
                                                show={modalEmailShow}
                                                onHide={() => setModalEmailShow(false)}
                                                setEmailActive={setEmailActive}
                                                setCount={setCount}
                                                count={count}
                                            />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}

export default WithdrawalNotification