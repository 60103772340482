import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const AuthenticationModal = (props) => {

    const VerficationSubmit = (event) => {
        event.preventDefault();
        props.setAuthActive(true);
        props.setCount( props.count < 2 ? props.count + 1 : props.count);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='authentication-modal'
        >
            <form onSubmit={VerficationSubmit}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='text-center'>AUTHENTICATOR <span className="text-blue">APP VERIFICATION</span></h3>
                    <p  className='sub-heading'>Enter 6-Digit-Code generated by authenticator app</p>
                    <div className='position-relative'>
                        <input className='form-control number-input' type='number' placeholder='Withdrawal Amount' />
                        <span className='number-span'>USDC MAX</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='Submit' className='btn-solid' onClick={props.onHide}>submit</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AuthenticationModal
