import React, { useState } from "react";
import CustomSelect from "../Components/CustomSelect";
import { useNavigate } from "react-router-dom";
import config from "../config";

const cryptoAdress = [
  { value: "", label: "Select crypto Address / Binance Id" },
  { value: "crypto address", label: "Crypto Address" },
  { value: "binance", label: "Binance" },
];

const networkOption = [{ value: "", label: "Select Network" }];

const options = [
  { value: "0.427 USDC", label: "0.427 USDC" },
  { value: "0.76 ETH", label: "0.76 ETH" },
  { value: "0.56 BNB", label: "0.56 BNB" },
];

const Withdrawal = () => {
  const [tradingSelectedOption, setTradingSelectedOption] = useState({ value: "", label: "Select Trading Account" });
  const [addressSelectedOption, setAddressSelectedOption] = useState({ value: "", label: "Select crypto Address / Binance Id" });
  const [networkSelectedOption, setNetworkSelectedOption] = useState({ value: "", label: "Select Network" });
  const [selectedOption, setSelectedOption] = useState({ value: "0.427 USDC", label: "0.427 USDC" });
  const navigate = useNavigate();

  const WithdrawalSubmit = () => {
    navigate("/withdrawal-notification");
  };

  const handleSelect = () => {};

  return (
    <section id="withdrawal">
      <div className="withdrawal">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="heading text-center text-white">Withdrawal</h2>
              <form onSubmit={WithdrawalSubmit}>
                <CustomSelect options={config.accountOptions} onSelect={handleSelect} defaultOption={tradingSelectedOption} />
                <CustomSelect options={cryptoAdress} onSelect={handleSelect} defaultOption={addressSelectedOption} />
                <CustomSelect options={networkOption} onSelect={handleSelect} defaultOption={networkSelectedOption} />
                <input className="form-control" type="text" placeholder="Enter Address" />
                <div className="position-relative">
                  <input className="form-control number-input" type="number" placeholder="Withdrawal Amount" />
                  <span className="number-span">USDC MAX</span>
                </div>
                <div className="availabe-amount d-flex align-items-center justify-content-between">
                  <span className="available-tag">Available</span>
                  <CustomSelect options={options} onSelect={handleSelect} defaultOption={selectedOption} />
                </div>
                <div className="checkboxes">
                  <label className="checkbox-container">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sagittis
                    <input type="checkbox" checked="checked" />
                    <span className="checkmark"></span>
                  </label>
                  <label className="checkbox-container">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sagittis
                    <input type="checkbox" checked="checked" />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="withdraw-amount d-flex align-items-center">
                  <div className="withdraw-data text-white">
                    <span className="text-grey">Receive Amount</span>
                    <span className="big-span">0.00 uSDC</span>
                    <span className="d-flex align-items-center text-grey">
                      Network fee <span className="ms-2 text-white">0.00 uSDC</span>
                    </span>
                  </div>
                  <button className="btn btn-solid justify-content-center text-uppercase">Withdraw</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Withdrawal;
