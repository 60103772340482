import React, { useState, useEffect } from "react";
import CustomSelect from "./CustomSelect";

const Investment = () => {
  const years = [
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];

  const coins = [
    { value: "SOL", label: "SOL" },
    { value: "ETH", label: "ETH" },
    { value: "MATIC", label: "MATIC" },
  ];

  const percentageData = {
    2020: { SOL: 699.72, MATIC: 1552.8, ETH: 516.58 },
    2021: { SOL: 586.44, MATIC: 1152.63, ETH: 244.64 },
    2022: { SOL: 66.8, MATIC: 79.44, ETH: 48.8 },
    2023: { SOL: 157.8, MATIC: 32.75, ETH: 54.85 },
  };

  const [defaultCoin, setDefaultCoin] = useState(coins[0]);
  const [defaultYear, setDefaultYear] = useState(years[0]);
  const [percentage, setPercentage] = useState("");

  const handleSelectCoins = (option) => {
    setDefaultCoin(option);
  };

  const handleSelectYear = (option) => {
    setDefaultYear(option);
  };

  const calculatePercentage = (year, coin) => {
    const yearValue = year.value;
    const coinValue = coin.value;
    const percentage = percentageData[yearValue][coinValue];
    setPercentage(`${percentage}%`);
  };

  useEffect(() => {
    calculatePercentage(defaultYear, defaultCoin);
  }, [defaultYear, defaultCoin]);

  return (
    <section id="investment">
      <div className="investment">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="investment-box">
                <div className="investment-box-inner">
                  <div>
                    If you would had invested with us
                    <CustomSelect options={years} onSelect={handleSelectYear} defaultOption={defaultYear} />
                  </div>
                  <div>
                    ago in
                    <CustomSelect options={coins} onSelect={handleSelectCoins} defaultOption={defaultCoin} />, your portfolio would have increased by{" "}
                    <strong>{percentage}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Investment;
