import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../config";
import Modal from "./DepositModal";
import Dropdown from "react-bootstrap/Dropdown";
import defaultImg from "../Assets/images/placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import axios, { all } from "axios";
const Navbar = ({ user }) => {
  const collapseRef = useRef(null);
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notificationList, setNotificationList] = useState();
  const [isMarkedAsRead, setisMarkedAsRead] = useState(true);
  const handleDropdownToggle = (isOpen, event, metadata) => {
    //console.log(isOpen);

    if (isOpen == false && isMarkedAsRead == false) {
      updateMarkAsRead();
    }
    setShowDropdown(isOpen);
  };

  const handleItemClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleLinkClick = () => {
    setTimeout(() => {
      if (collapseRef.current && collapseRef.current.classList.contains("show")) {
        collapseRef.current.classList.remove("show");
      }
    }, 300);
  };
  React.useEffect(() => {
    fetchNotifications();
  }, []);
  async function fetchNotifications() {
    try {
      // if (!user) {
      //   //setLoading(false); // Set loading to true when fetching starts
      //   return;
      // }
      setLoading(true); // Set loading to true when fetching starts
      const requestOptions = {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(`${config.baseURI}getAllNotifications`, requestOptions);
      //console.log(res);
      let allNotifications = res.data.userNotifications.latestNoti;
      console.log(allNotifications);
      // setBalances(walletBalances);
      setNotificationList(allNotifications);
      setisMarkedAsRead(res.data.userNotifications.markAsRead);
      setLoading(false); // Set loading to true when fetching starts
      return;
    } catch (error) {
      console.log(error);
      setLoading(false); // Set loading to true when fetching starts
    }
  }
  async function updateMarkAsRead() {
    try {
      //console.log("up");
      const requestOptions = {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${config.baseURI}markAllAsRead`, {}, requestOptions);
      //console.log(res);
      if (res.data.result == true) {
        setisMarkedAsRead(true);
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }
  // const [isModalOpen, setIsModalOpen] = React.useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  //   document.querySelector(".navbar").style.zIndex = 0;
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   document.querySelector(".navbar").style.zIndex = 4;
  // };

  return (
    <nav id="navBar" className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand order-first" to="/" onClick={handleLinkClick}>
          <img src="images/logo.png" className="img-fluid" alt="Invest Block-C Tech" />
        </Link>

        <div className="collapse navbar-collapse order-lg-second order-last" id="navbarSupportedContent" ref={collapseRef}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link prompt-medium" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link prompt-medium" onClick={handleLinkClick}>
                Dashboard
              </Link>
            </li>
          </ul>
          {!user && (
            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={() => {
                  window.location.href = config.baseURI + "auth/google";
                }}
                className="btn btn-regular login-button"
              >
                Login
              </button>
              <button
                onClick={() => {
                  window.location.href = config.baseURI + "auth/google";
                }}
                className="btn btn-solid login-button"
              >
                Register
              </button>
            </div>
          )}
        </div>
        <div className="navbar-dropdowns d-flex order-lg-last order-first">
          {user && (
            <div className="dashboard-dropdowns d-flex align-items-center">
              <Dropdown show={showDropdown} onToggle={handleDropdownToggle} className="notifications-dropdown">
                <Dropdown.Toggle variant="success" id="noti-button">
                  <FontAwesomeIcon icon={faBell} />
                  {!isMarkedAsRead && <span className="red-dot"></span>}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {loading ? (
                    <div className="text-center" style={{ alignContent: "center", minHeight: "250px" }}>
                      <div className="spinner-border text-white" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!notificationList || notificationList.length == 0 ? (
                        <a>No Notifications</a>
                      ) : (
                        <>
                          {" "}
                          {notificationList.map((single) => (
                            <>
                              <Dropdown.Item href="#" onClick={handleItemClick}>
                                {single}{" "}
                              </Dropdown.Item>
                              <Dropdown.Divider />
                            </>
                          ))}
                          {/* <Dropdown.Item href="#" onClick={handleItemClick}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="#" onClick={handleItemClick}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="#" onClick={handleItemClick}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </Dropdown.Item>
                          <Dropdown.Divider /> */}
                        </>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="user-dropdown">
                <Dropdown.Toggle variant="success" id="user-button">
                  <div className="user-img">
                    <img className="img-fuid" src={user?.photos} alt="User Image" />
                  </div>
                  <div className="user-data">
                    <span className="user-name d-block">{user?.displayName.toString().split(" ")[0]}</span>
                    <span className="user-role d-block">Regular User</span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                  <div className="menu-user-data d-sm-none d-block">
                    <span className="user-name d-block mb-0">{user?.displayName}</span>
                    <span className="user-role d-block">Regular User</span>
                  </div>
                  <Dropdown.Divider className="d-sm-none d-block" />
                  <Dropdown.Item href="">
                    <span className="color-blue">--</span> Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <span className="color-blue">--</span> Settings
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <span className="color-blue">--</span> Withdraw
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <span className="color-blue">--</span> Referral
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <span className="color-blue">--</span> Security
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href=""
                    onClick={() => {
                      window.location.href = config.baseURI + "auth/logout";
                    }}
                  >
                    <span className="color-blue">--</span> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <button
            className="navbar-toggler ms-4 "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        {/* <Modal isModalOpen={isModalOpen} closeModal={closeModal} /> */}
      </div>
    </nav>
  );
};

export default Navbar;
