import config from "../config";
export const fetchAddress = async (tokenName) => {
  //console.log(tokenName);
  const requestOptions = {
    credentials: "include",
    method: "POST", // Using POST method to send data
    headers: {
      "Content-Type": "application/json", // Specify the content type in the header
    },
    body: JSON.stringify({ tokenName }), // Convert the JavaScript object to a JSON string
  };

  try {
    const response = await fetch(config.baseURI + `checkWallet`, requestOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = await response.json(); // Assuming the server responds with JSON
    // console.log("data.walletAddress:", data.walletAddress);
    //setDepositAddress(data.walletAddress);
    return data.walletAddress;
  } catch (error) {
    console.error("Error:", error);
  }
};
