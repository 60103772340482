import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleAuthModal from "../Components/GoogleAuthModal";
import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../config";

// const totalAuths = [
//   // { id: 1, title: "Authenticator 1", date: "12/03/2024" },
//   // { id: 2, title: "Authenticator 2", date: "12/03/2024" },
//   // { id: 3, title: "Authenticator 3", date: "12/03/2024" },
// ];

const AddAuthenticator = () => {
  const [auths, setAuths] = useState(false);
  const [totalAuths, setTotalAuths] = useState([]);
  const [activeTabs, setActiveTabs] = useState(totalAuths.map(() => false));
  const navigate = useNavigate();
  const [googleAuthShow, setGoogleAuthShow] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    fetchtwoFAList();
    if (totalAuths.length === 0) {
      setAuths(false);
    }
  }, [totalAuths]);
  const fetchtwoFAList = async () => {
    try {
      const response = await axios.get(config.baseURI + `twofa/getList`, {
        headers: {
          "Content-Type": "application/json", // Specify the content type in the header
        },
        withCredentials: true, // Include credentials
      });

      const data = response.data; // Axios automatically parses JSON responses
      if (data.result) {
        console.log(data.list);
        setTotalAuths(data.list);
      }
    } catch (error) {
      console.error("Error:", error);
      return;
    }
  };
  const activeAuth = (index) => {
    setActiveTabs((prevState) => prevState.map((tab, idx) => (idx === index ? !tab : tab)));
  };

  const withdrawalPage = () => {
    navigate("/withdrawal");
  };

  return (
    <section id="add-authenticator">
      <div className="add-authenticator withdrawal">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="heading text-center text-white">
                AUTHENTICATOR <span className="color-blue">APP</span>
              </h2>
              {totalAuths ? (
                <div className="all-auths">
                  {totalAuths.map((auth, index) => (
                    <div className={`inner-auths ${activeTabs[index] ? "active" : ""}`} key={index}>
                      <div className="inner-auth-tab" onClick={() => activeAuth(index)}>
                        <h2 className="mb-0">{auth.title}</h2>
                        <span className="trash-icon d-block">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                              alert("Unauthorized");
                            }}
                          />
                        </span>
                      </div>
                      <div className="d-flex justify-content-end">
                        <h3>
                          Added : <span>{auth.date}</span>
                        </h3>
                      </div>
                    </div>
                  ))}
                  <div className="continue-withdrawal">
                    {/* <button className="btn btn-solid mx-auto" disabled={!activeTabs.includes(true)} onClick={withdrawalPage}>
                      Continue
                    </button> */}
                  </div>
                </div>
              ) : (
                <div className="no-auths">
                  <h2>Your list is empty !</h2>
                  <div className="add-auth-modal">
                    <Button className="modal-button mx-auto btn-solid" onClick={() => setGoogleAuthShow(true)}>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="tag">Add Now</span>
                      </div>
                    </Button>

                    <GoogleAuthModal show={googleAuthShow} onHide={() => setGoogleAuthShow(false)} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddAuthenticator;
