import React from "react";
import loginSvg from "../Assets/login.svg";
import { useNavigate } from "react-router-dom";

const GoogleAuth = () => {
  const navigate = useNavigate();

  const googleAuthSubmit = () => {
   navigate("/add-authenticators");
  }

  return (
    <section id="login">
      <div className="login google-auth">
        <div className="container position-relative">
          <span className="star">+</span>
          <span className="star">+</span>
          <span className="star">+</span>
          <div className="row">
            <div className="col-12 text-center">
              <img src={loginSvg} alt="Login" className="img-fluid" />
              <h2 className="title">
                YOu NEED TO ENABLE GOOGLE <span>AUTHENTICATOR</span>
              </h2>
              <p className="description"></p>
              <button
                onClick={googleAuthSubmit}
                className="btn btn-solid mx-auto"
              >
                Enable Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoogleAuth;
