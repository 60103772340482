import React from "react";

const Benefits = () => {
  return (
    <section id="benefits">
      <div className="benefits">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="title">BENEFITS</h3>
              <p className="description">
                Discover the advantages of our innovative platform designed to enhance your trading experience. From top-notch security to effortless
                withdrawals and optimized profits.
              </p>

              <div className="benefits-boxes">
                <div className="benefits-box">
                  <div className="box-title">1</div>
                  <div className="box-desc">
                    <h4 className="title">Safe and Secure</h4>
                    <p className="description">We use the industry's best encryption algorithms to protect your accounts from hacking.</p>
                  </div>
                </div>
                <div className="benefits-box">
                  <div className="box-title">2</div>
                  <div className="box-desc">
                    <h4 className="title">Withdraw</h4>
                    <p className="description">Withdraw your funds anytime you want with 0% fees.</p>
                  </div>
                </div>
                <div className="benefits-box">
                  <div className="box-title">3</div>
                  <div className="box-desc">
                    <h4 className="title">Profits</h4>
                    <p className="description">Sit back, relax and let our algorithms trade for you in a safe and less stressful way.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
