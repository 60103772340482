import React from "react";
import DashboardHero from "../Components/DashboardHero";
import Portfolio from "../Components/Portfolio";
import config from "../config";
const Dashboard = ({ user }) => {
  const [defaultProtfolioToken, setdefaultProtfolioToken] = React.useState(config.accountOptions[0]);
  return (
    <>
      <DashboardHero user={user} setdefaultProtfolioToken={setdefaultProtfolioToken} />
      <Portfolio defaultProtfolioToken={defaultProtfolioToken} />
    </>
  );
};

export default Dashboard;
