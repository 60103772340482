import React from "react";
import loginSvg from "../Assets/login.svg";
import config from "../config";

const Login = () => {
  return (
    <section id="login">
      <div className="login">
        <div className="container position-relative">
          <span className="star">+</span>
          <span className="star">+</span>
          <span className="star">+</span>
          <div className="row">
            <div className="col-12 text-center">
              <img src={loginSvg} alt="Login" className="img-fluid" />
              <h2 className="title">
                OOPS ! <span>LOGIN NOW</span>
              </h2>
              <p className="description">It looks like you need to log in to access this page. Please log in to continue enjoying our services.</p>
              <button
                onClick={() => {
                  window.location.href = config.baseURI + "auth/google"; // Programmatically redirect
                }}
                className="btn btn-solid mx-auto"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
