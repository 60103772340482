import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import QRCode from "react-qr-code";
import config from "../config";
import axios from "axios";
import { message } from "antd";
const GoogleAuthModal = (props) => {
  const [isLoading, setisLoading] = useState(true);
  const [otpauth_url, setotpauth_url] = useState("");
  const [twofacode, setTwofacode] = useState("");

  const fetchtwoFADetails = async () => {
    try {
      const response = await axios.get(config.baseURI + `twofa/generate`, {
        headers: {
          "Content-Type": "application/json", // Specify the content type in the header
        },
        withCredentials: true, // Include credentials
      });

      const data = response.data; // Axios automatically parses JSON responses
      setotpauth_url(data.otpauth_url);
      console.log(data.otpauth_url);

      return data.otpauth_url;
    } catch (error) {
      console.error("Error:", error);
      message.error("Error");

      return;
    }
  };

  useEffect(() => {
    console.log("useeffect");
    fetchtwoFADetails();
  }, []);

  const VerficationSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        config.baseURI + `twofa/addtwofactor`,
        { twofacode },
        {
          headers: {
            "Content-Type": "application/json", // Specify the content type in the header
          },
          withCredentials: true, // Include credentials
        }
      );
      console.log(response.data);
      message.success("2FA added successfully");
      // Handle success response here
    } catch (error) {
      if (error.response.data == "Invalid token") {
        message.error(error.response.data);

        return;
      }

      console.error("Error:", error);
      message.error("Error");

      return;

      // Handle error response here
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="authentication-modal add-modal">
      <form onSubmit={VerficationSubmit}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3 className="text-center">
            ADD <span className="text-blue">AUTHENTICATOR</span>
          </h3>
          <div className="qr-code">
            <QRCode value={otpauth_url} />
          </div>
          <p>Open authenticator app and scan the QR code</p>
          <div className="position-relative">
            <input className="form-control" type="text" placeholder="Enter Code" value={twofacode} onChange={(e) => setTwofacode(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn-solid" onClick={props.onHide}>
            Add Authenticator
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default GoogleAuthModal;
