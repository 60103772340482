import React from 'react';
import mongo from "../Assets/mongo.svg";
import infura from '../Assets/infura.svg';
import etherJs from '../Assets/etherJs.svg';
import uniswap from '../Assets/uniswap.svg';

const Analytics = () => {
  return (
    <section id='analytics'>
      <div className="analytics">
        <div className="container">
          <div className="row powered-by">
            <div className="col-12">
              <h2 className='title'>POWERED BY <span className='color-blue'>WORLD'S LEADING</span> TECHONOLOGIES</h2>
              <div className="boxes">
                <div className="box">
                  <img src={etherJs} alt="Ether JS" className='img-fluid' />
                  Ether.js
                </div>
                <div className="box">
                  <img src={infura} alt="infura" className='img-fluid' />
                  Infura
                </div>
                <div className="box">
                  <img src={mongo} alt="mongo" className='img-fluid' />
                </div>
                <div className="box">
                  <img src={uniswap} alt="uniswap" className='img-fluid' />
                </div>
              </div>
            </div>
          </div>

          <div className="row analytics-inner">
            <div className="col-12">
              <h2 className="title">Our 5-Year <span className='color-blue'>Backtested Algorithms</span> will help you in growing your portfolio</h2>
              <div className="lg-boxes">
                <div className="lg-box">
                  <p className="description">If you would've invested with us 5 years ago, your portfolio would have <span className='color-blue'>increased by 486%</span>.</p>
                  <div className="lg-box-img">
                    <img src="/images/graph.png" alt="Graph" className='img-fluid' />
                  </div>
                </div>
                <div className="lg-box">
                  <p className="description"><span className='color-blue'>Decentralized Auto Investment Platform</span>  Let our algorithms decide when and where to buy & sell, Our algorithms are trained to make safe Investment, So you are always profitable</p>
                  <div className="lg-box-logs">
                    <div className="log">
                      <div className="log-index">
                        1
                      </div>
                      <div className="log-details">
                        <span className='chain'>Xx-Wardog-Xx</span>
                        <span className='pair'>BIIS/USDT</span>
                      </div>
                      <div className="log-time">
                        <span className="time">126D 14H 4AM</span>
                        <span className="percentage">27.47%</span>
                      </div>
                    </div>
                    <div className="log">
                      <div className="log-index">
                        1
                      </div>
                      <div className="log-details">
                        <span className='chain'>Xx-Wardog-Xx</span>
                        <span className='pair'>BIIS/USDT</span>
                      </div>
                      <div className="log-time">
                        <span className="time">126D 14H 4AM</span>
                        <span className="percentage">27.47%</span>
                      </div>
                    </div>
                    <div className="log">
                      <div className="log-index">
                        1
                      </div>
                      <div className="log-details">
                        <span className='chain'>Xx-Wardog-Xx</span>
                        <span className='pair'>BIIS/USDT</span>
                      </div>
                      <div className="log-time">
                        <span className="time">126D 14H 4AM</span>
                        <span className="percentage">27.47%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Analytics
