import React from 'react'
import HomeHero from '../Components/HomeHero'
import Analytics from '../Components/Analytics'
import Benefits from '../Components/Benefits'
import Investment from '../Components/Investment'

const Home = () => {
  return (
    <>
      <HomeHero />
      <Analytics />
      <Investment />
      <Benefits />
    </>
  )
}

export default Home
