import React from "react";
import downArrow from "../Assets/downArrow.svg";
import { Link } from "react-router-dom";

const HomeHero = () => {
  return (
    <section id="home-hero">
      <div className="home-hero">
        <div className="container position-relative">
          <span className="star">+</span>
          <span className="star">+</span>
          <span className="star">+</span>
          <div className="row">
            <div className="col-12">
              <h1 className="title text-center text-white">
                Let’s build <span className="color-blue">wealth</span>
              </h1>
              <h2 className="sub-title">invest smartly in automated way</h2>

              <div className="down-arrow mt-80 mb-80">
                <img src={downArrow} className="img-fluid icon" alt="downArrow" />
              </div>

              <div className="dashboard-buttons d-flex justify-content-center">
                <Link to="/dashboard" className="btn btn-solid justify-content-center">
                  LETS GET STARTED
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
