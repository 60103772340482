import React, { useState, useRef } from "react";

const CustomSelect = ({ options, onSelect, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption || null);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    console.log(option);
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // React.useEffect(() => {
  //   if (defaultProtfolioToken == undefined) return;
  //   setSelectedOption(defaultProtfolioToken);
  //   setIsOpen(false);
  //   onSelect(defaultProtfolioToken);
  // }, [defaultProtfolioToken]);

  return (
    <div className="custom-select-container" ref={dropdownRef}>
      <div className={`custom-select-header ${isOpen ? "custom-select-header--open" : ""}`} onClick={handleToggleDropdown}>
        {selectedOption ? (
          <>
            <span className="custom-select-header-text">
              {selectedOption.label}
              {selectedOption.volatility ? " - High Volatility" : ""}
            </span>
          </>
        ) : (
          "Select an option"
        )}
        <span className={`custom-select-arrow ${isOpen ? "custom-select-arrow--open" : ""}`}>
          <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0L8 7.5L15.5 0H0.5Z" fill="white" />
          </svg>
        </span>
      </div>
      {isOpen && (
        <ul className="custom-select-options">
          {options.map((option) => (
            <li key={option.value} className="custom-select-option" onClick={() => handleOptionClick(option)}>
              {option.label}
              {option.volatility ? " - High Volatility" : ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
