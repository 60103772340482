// src/config.js

const config = {
  //baseURI: "https://autobott-3419b6db4bfa.herokuapp.com/",
  baseURI: process.env.REACT_APP_LINK,
  accountOptions: [
    { value: "BITCOIN", label: "BITCOIN TRADING ACCOUNT", pair: "BTCUSDT", volatility: false },

    { value: "ETHEREUM", label: "ETHEREUM TRADING ACCOUNT", pair: "ETHUSDT", volatility: false },

    { value: "SOLANA", label: "SOLANA TRADING ACCOUNT", pair: "SOLUSDT", volatility: false },
    { value: "POLYGON", label: "POLYGON TRADING ACCOUNT", pair: "POLUSDT", volatility: false },

    { value: "TIA", label: "TIA TRADING ACCOUNT", pair: "TIAUSDT", volatility: true },

    { value: "INJ", label: "INJ TRADING ACCOUNT", pair: "INJUSDT", volatility: true },

    { value: "SUI", label: "SUI TRADING ACCOUNT", pair: "SUIUSDT", volatility: true },
    // { value: "SHIBA", label: "SHIBA TRADING ACCOUNT", pair: "SHIBUSDT" },
  ],
};

export default config;
